import React from "react";
import logo from "./logo.svg";
import "./App.css";
import ScormReader from "./ScormReader";
import config from "./config";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";

interface IState {
  loading: boolean;
  data: any;
  scormCode: string;
  firstname: string;
  lastname: string;
  learnerId: string;
  suspendData: string;
  errored: boolean;
  requesting: boolean;
}
class App extends React.Component<any, IState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      scormCode: "",
      firstname: "",
      lastname: "",
      learnerId: "",
      suspendData: "",
      errored: false,
      requesting: false,
    };
  }
  componentDidMount(): void {
    window.addEventListener("message", this.parentListener);
  }
  parentListener = async (event) => {
    if (event.data.message === "initialize")
      this.setState(
        {
          learnerId: event.data.value.learnerId,
          firstname: event.data.value.learnerName,
          lastname: event.data.value.learnerName,
          scormCode: event.data.value.scormCode,
        },
        this.checkData
      );
  };

  async checkData() {
    if (
      this.state.learnerId !== "" &&
      this.state.firstname !== "" &&
      this.state.scormCode !== "" &&
      !this.state.requesting &&
      !this.state.data
    ) {
      this.setState({ requesting: true }, async () => {
        const data = await this.getData();
        this.setState({
          loading: false,
          data,
          suspendData: data.p.suspendData,
          requesting: false,
        });
      });
    }
  }
  /*var settings = { logLevel: 1 };
  var learner = {
    learner_id: "123",
    learner_name: "Caremoli Thibaud",
    session_time: "PT4M0.1S",
    suspend_data: `{"v":2,"d":[123,34,112,114,111,103,114,101,115,115,34,58,256,108,263,115,111,110,265,267,34,48,266,256,112,266,49,55,44,34,105,278,276,287,99,281,284,286,275,277,275,290,58,49,125,300,284,49,289,291,285,287,295,256,297,299,301,34,50,287,293,123,312,51,315,278,312,52,320,267,312,53,324,317,125,284,54,328,312,55,333,330,34,57,336,302,308,306,325,337,49,303,275,316,300,312,347,309,305,280,298,48,48,292,307,304,298,359,294,361,311,345,365,363,256,342,310,350,284,314,296,305,316,288,376,298,373,34,319,380,49,369,379,353,381,322,368,343,370,365,382,327,385,387,371,281,382,332,398,393,388,34,372,302,395,334,395,284,56,392,378,400,390,337,339,385,382,375,389,386,257,281,357,399,415,360,421,351,430,364,432,337,423,407,377,431,424,382,384,424,429,436,284,323,404,416,410,337,397,445,405,417,366,331,434,394,447,34,335,450,441,439,381,409,462,414,421,284,420,455,451,436,318,392,355,49,428,456,460,406,372,433,465,435,442,312,438,310,446,490,337,444,467,425,476,495,448,484,457,396,503,452,459,488,461,501,463,506,472,34,352,498,382,471,442,473,513,495,391,275,355,52,358,483,509,485,401,487,475,466,486,437,522,517,478,530,504,524,256,349,453,340,34,403,544,321,337,464,550,344,521,348,551,382,480,54,300],"cpv":"rglFh2oN"}`,
  };
  //@ts-ignore
  window.API_1484_11 = new window.Scorm2004API(settings);
  //@ts-ignore
  window.API_1484_11.loadFromJSON(learner);
  //@ts-ignore
  window.API_1484_11.on("SetValue.cmi.*", (CMIElement, value) => {
    console.log(CMIElement, value);
    if (CMIElement === "cmi.progress_measure") {
      let percent = Math.round(value * 100);
      console.log("progress", percent);
    }
  });
  //@ts-ignore
  setInterval(() => {
    //window.API_1484_11.SetValue("learner_name", "bob legob");
    //window.API_1484_11.Commit();

    //@ts-ignore
    let el = document
      .getElementById("toto")

      //@ts-ignore
      .contentDocument.getElementById("content-frame")
      .contentDocument.getElementsByClassName(
        "nav-sidebar-header__progress-text"
      );
    if (el && el[0]) {
      console.log("progress : ", el[0].innerText);
    }

    //console.log("oooooooo", window.API_1484_11.cmi.suspend_data);
  }, 2500);*/

  public render(): JSX.Element | null {
    if (this.state.loading) {
      return <LoadingPage />;
    }
    if (!this.state.data) return null;
    if (this.state.errored) {
      return (
        <ErrorPage
          message={
            "Une erreur est survenue lors de la connexion avec Fasterclass, vérifiez que vous aillez une connexion internet et réessayez."
          }
        />
      );
    } else {
      return (
        <main style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <ScormReader
              url={this.state.data.url}
              lang="fr"
              firstname={this.state.firstname}
              lastname={this.state.firstname}
              learnerId={this.state.firstname}
              suspendData={this.state.suspendData}
            />
          </div>
        </main>
      );
    }
  }
  async getData() {
    const res = await fetch(config.apiUrl + "auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        scormCode: this.state.scormCode,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        learnerId: this.state.learnerId,
      }),
    });

    // The return value is *not* serialized
    // You can return Date, Map, Set, etc.

    if (!res.ok) {
      this.setState({ errored: true });
    }
    return res.json();
  }
}

export default App;
