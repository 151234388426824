import React from "react";

interface IProps {
  message: string;
}
interface IState {}
class ErrorPage extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element | null {
    return (
      <div style={{ height: "100%" }}>
        <div
          style={{
            flex: 1,
            border: 0,
            height: "100%",
            width: "70%",
            margin: "100px auto",
          }}
          id={"scorm-error"}
        >
          <h1>{this.props.message}</h1>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
