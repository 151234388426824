const ENV: IEnvsConfig = {
  dev: {
    apiUrl: "http://localhost:10040/api/",
  },
  prod: {
    apiUrl: "https://scorm.fasterclass.fr/api/",
  },
};
interface IEnvsConfig {
  dev: IConfig;
  prod: IConfig;
}
interface IConfig {
  apiUrl: string;
}
export interface IConfigDev {
  apiUrl: {
    ios: string;
    android: string;
  };
}

export default ENV.prod;
