import React from "react";
import loaderGif from "./assets/loader.gif";
import logo from "./assets/logo_fasterclass.png";

interface IProps {}
interface IState {}
class LoadingPage extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element | null {
    return (
      <div style={{ height: "100%" }}>
        <div
          style={{
            flex: 1,
            border: 0,
            width: "70%",
            margin: "0 auto",
            padding: "200px 50px",
            fontFamily: "lato",
            fontWeight: 100,
            textAlign: "center",
          }}
          id={"scorm-error"}
        >
          <div>
            <img src={logo} style={{ width: 200, marginBottom: 50 }} />
          </div>
          <img src={loaderGif} style={{ width: 70, height: 70 }} />
        </div>
      </div>
    );
  }
}

export default LoadingPage;
