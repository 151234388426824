import React from "react";
import config from "./config";
import "scorm-again";

interface IProps {
  url: string;
  firstname: string;
  lastname: string;
  learnerId: string;
  suspendData: string;
  lang: "fr" | "en";
}
interface IState {
  loading: boolean;
  lastSessionTime: number;
  newSessionTime: number;
}
class ScormReader extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      lastSessionTime: 0,
      newSessionTime: 0,
    };
  }
  async componentDidMount() {
    var settings = {}; //logLevel: 0 };
    var learner = {
      learner_id: this.props.learnerId, //+ props.user.id,
      learner_name: this.props.firstname + " " + this.props.lastname, //props.user.lastname + ' ' + props.user.firstname,
      suspend_data: this.props.suspendData,
    };
    // @ts-ignore
    window.API_1484_11 = new window.Scorm2004API(settings);
    // @ts-ignore
    window.API_1484_11.loadFromJSON(learner);
    // @ts-ignore
    window.API_1484_11.on("SetValue.cmi.*", (CMIElement, value) => {
      window.parent.postMessage(
        {
          message: "SetValue.cmi.*",
          value: {
            CMIElement,
            value,
          },
        },
        "*"
      );

      if (CMIElement.includes("cmi.interactions")) {
        this.storeInterraction(CMIElement, value);
      }
      if (CMIElement === "cmi.session_time") {
        this.storeTime(value);
      }
      if (CMIElement === "cmi.suspend_data") {
        let el = document
          .getElementById("scorm-reader")
          //@ts-ignore
          .contentDocument.getElementById("content-frame")
          .contentDocument.getElementsByClassName(
            "nav-sidebar-header__progress-text"
          );
        if (el && el[0]) {
          let completion = el[0].innerText.split("%");
          if (completion && completion[0]) completion = completion[0];
          this.setProgress(value, completion);
        }
      }
    });
    this.setState({ loading: false });
  }
  public render(): JSX.Element | null {
    const { lang, url } = this.props;
    const { loading } = this.state;
    return (
      <div style={{ height: "100%" }}>
        <iframe
          style={{ flex: 1, border: 0, height: "100%", width: "100%" }}
          src={config.apiUrl + "static/cohorts/" + url}
          id={"scorm-reader"}
        ></iframe>
      </div>
    );
  }
  private progress = (percent: number) => {
    /*if (this.props.cohort.participate.id > 0) {
      this.props.dispatch({
        type: COHORT_PROGRESS,
        payload: {
          percent: percent,
          cohortId: this.props.cohort.id,
        },
      })
    }*/
  };
  private interraction: {
    id?: string;
    type?: string;
    learner_response?: string;
    result?: string;
    correct_responses?: string;
    description?: string;
    weighting?: number;
    latency?: string;
    objectives?: string;
    timestamp?: string;
  } = {};
  private async storeInterraction(cmiEl, value) {
    let cat = cmiEl.replace("cmi.interactions.", "").split(".")[1];
    let toAdd = {};
    toAdd[cat] = value;
    this.interraction = {
      ...this.interraction,
      ...toAdd,
    };
    if (
      this.interraction.id &&
      this.interraction.type &&
      this.interraction.learner_response &&
      this.interraction.result &&
      this.interraction.correct_responses &&
      this.interraction.description &&
      this.interraction.weighting &&
      this.interraction.latency &&
      this.interraction.objectives &&
      this.interraction.timestamp
    ) {
      const res = await fetch(config.apiUrl + "private/cohorts/interract", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(this.interraction),
      });
      this.interraction = {};
    }
  }
  private storeTime(value) {
    value = value.substr(2);
    let seconds = 0;
    if (value.includes("D")) {
      value = value.split("D");
      seconds += Math.round(value[0]) * 24 * 60 * 60;
      value = value[1];
    }
    if (value.includes("H")) {
      value = value.split("H");
      seconds += Math.round(value[0]) * 60 * 60;
      value = value[1];
    }
    if (value.includes("M")) {
      value = value.split("M");
      seconds += Math.round(value[0]) * 60;
      value = value[1];
    }
    if (value.includes("S")) {
      value = value.split("S");
      seconds += Math.round(value[0]) * 1;
      value = value[1];
    }
    this.setState({
      newSessionTime: seconds,
    });
  }
  private async setProgress(suspendData, completion) {
    let timer = this.state.newSessionTime - this.state.lastSessionTime;
    this.setState({
      lastSessionTime: this.state.newSessionTime,
    });
    window.parent.postMessage(
      {
        message: "SetValue.cmi.*",
        value: {
          CMIElement: "cmi.progress_measure",
          value: completion / 100,
        },
      },
      "*"
    );
    const res = await fetch(config.apiUrl + "private/cohorts/progress", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        suspendData,
        completion,
        timer,
      }),
    });

    if (!res.ok) {
      throw new Error("Failed to fetch data");
    }
    return res.json();
  }
}

export default ScormReader;
